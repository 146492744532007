<template>
  <div class="customer-chooser-component">
    <button v-if="customers.length > 1" type="button" class="btn btn-success" data-toggle="modal" data-target="#choose-customer">{{__('vue.customer')}}</button><br/>
    <span v-if="selectedCustomer">
      {{ selectedCustomer.company }}<br/>
      {{ selectedCustomer.street }} {{ selectedCustomer.streetNumber }}<br/>
      {{ selectedCustomer.zip }} {{ selectedCustomer.city }}<br/>
      {{ selectedCustomer.country }}<br/>
      {{ selectedCustomer.vat }}<br/>
      <input type="hidden" name="customer_id" v-model="selectedCustomer.id">
      <input type="hidden" name="forceCustomerUpdate" v-model="forceCustomerUpdate">
    </span>

    <div class="modal fade" id="choose-customer" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel">{{__('vue.customer')}}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
          </div>
          <div class="modal-body">
            <nav>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <a class="nav-item nav-link active" id="nav-choosecustomer-tab" data-toggle="tab" href="#nav-choosecustomer" role="tab" aria-controls="nav-home" aria-selected="true">{{__('vue.select_customer')}}</a>
                <a class="nav-item nav-link" id="nav-addcustomer-tab" data-toggle="tab" href="#nav-addcustomer" role="tab" aria-controls="nav-profile" aria-selected="false">{{__('vue.add_customer')}}</a>
                <a class="nav-item nav-link" id="nav-editcustomer-tab" data-toggle="tab" href="#nav-editcustomer" role="tab" aria-controls="nav-profile" aria-selected="false">{{__('vue.edit_customer')}}</a>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active" id="nav-choosecustomer" role="tabpanel" aria-labelledby="nav-choosecustomer-tab">
                <form method="POST" enctype="multipart/form-data" v-on:submit.prevent="createItem">
                  <v-server-table url="/api/customers-table" :columns="columns" :options="options" @row-click="rowClicked"/>
                </form>
              </div>
              <div class="tab-pane fade show" id="nav-addcustomer" role="tabpanel" aria-labelledby="nav-addcustomer-tab">
                <div class="alert alert-danger" role="alert" id="customer-create-alert" v-show="hasError">
                  <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                  {{ error }}
                </div>
                <form method="POST" enctype="multipart/form-data" v-on:submit.prevent>
                  <div class="form-group">
                    <label for="title">{{__('vue.company')}}</label>
                    <input placeholder="Company" type="text" name="company" class="form-control" v-model="addCustomer.company" />
                  </div>
                  <div class="form-group">
                    <div class="form-row">
                      <div class="col">
                        <label for="title">{{__('vue.first_name')}}</label>
                        <input placeholder="First Name" type="text" name="firstName" class="form-control" v-model="addCustomer.firstname" />
                      </div>
                      <div class="col">
                        <label for="title">{{__('vue.last_name')}}</label>
                        <input placeholder="Last Name" type="text" name="lastName" class="form-control" v-model="addCustomer.lastname" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="title">{{__('vue.email')}}</label>
                    <input placeholder="Email" type="text" name="email" class="form-control" v-model="addCustomer.email" />
                  </div>
                  <div class="form-group">
                    <div class="form-row">
                      <div class="col-md-10">
                        <label for="title">{{__('vue.street')}}</label>
                        <input placeholder="Street" type="text" name="street" class="form-control" v-model="addCustomer.street" />
                      </div>
                      <div class="col-md-2">
                        <label for="title">{{__('vue.number')}}</label>
                        <input placeholder="Number" type="text" name="streetNumber" class="form-control" v-model="addCustomer.streetNumber" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-row">
                      <div class="col-md-4">
                        <label for="title">{{__('vue.zip')}}</label>
                        <input placeholder="ZIP" type="text" name="zip" class="form-control" v-model="addCustomer.zip" @blur="zipChanged" />
                      </div>
                      <div class="col-md-8">
                        <label for="title">{{__('vue.city')}}</label>
                        <input placeholder="City" type="text" name="city" id ="city" class="form-control" v-model="addCustomer.city" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="title">{{__('vue.country')}}</label>
                    <input placeholder="Country" type="text" name="country" class="form-control" v-model="addCustomer.country" />
                  </div>
                  <div class="form-group">
                    <div class="form-row">
                      <div class="col-md-8">
                        <label for="title">{{__('vue.vat_number')}}</label>
                        <input placeholder="VAT" type="text" name="vat" class="form-control" v-model="addCustomer.vat" />
                      </div>
                      <div class="col-md-4">
                        <label for="title">{{__('vue.vat_type')}}</label>
                        <select name="vatType" class="form-control" v-model="addCustomer.vatType">
                          <option value="0" selected="selected">0%</option>
                          <option value="12">12%</option>
                          <option value="6">6%</option>
                          <option value="21">21%</option>
                          <option value="intra">{{__('vue.intra')}}</option>
                          <option value="mc">{{__('vue.mc')}}</option>
                          <option value="no">{{__('vue.no_vat')}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="title">{{__('vue.payment_condition')}}'</label>
                        <input placeholder="Payment condition (days):" type="number" name="paymentCondition" class="form-control" v-model="addCustomer.paymentCondition" />
                      </div>
                    </div>
                  </div>
                  <a v-on:click="addCustomerClicked" class="btn btn-primary">{{__('vue.add_customer')}}</a>
                </form>

              </div>
              <div class="tab-pane fade show" id="nav-editcustomer" role="tabpanel" aria-labelledby="nav-editcustomer-tab">
                <div class="alert alert-danger" role="alert" id="customer-create-alert" v-show="hasError">
                  <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                  {{ error }}
                </div>
                <form method="POST" enctype="multipart/form-data" v-on:submit.prevent>
                  <div class="form-group">
                    <label for="title">{{__('vue.company')}}</label>
                    <input placeholder="Company" type="text" name="editcompany" class="form-control" v-model="editCustomer.company" />
                  </div>
                  <div class="form-group">
                    <div class="form-row">
                      <div class="col">
                        <label for="title">{{__('vue.first_name')}}</label>
                        <input placeholder="First Name" type="text" name="editfirstName" class="form-control" v-model="editCustomer.firstname" />
                      </div>
                      <div class="col">
                        <label for="title">{{__('vue.last_name')}}</label>
                        <input placeholder="Last Name" type="text" name="editlastName" class="form-control" v-model="editCustomer.lastname" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="title">{{__('vue.email')}}</label>
                    <input placeholder="Email" type="text" name="editemail" class="form-control" v-model="editCustomer.email" />
                  </div>
                  <div class="form-group">
                    <div class="form-row">
                      <div class="col-md-10">
                        <label for="title">{{__('vue.street')}}</label>
                        <input placeholder="Street" type="text" name="editstreet" class="form-control" v-model="editCustomer.street" />
                      </div>
                      <div class="col-md-2">
                        <label for="title">{{__('vue.number')}}</label>
                        <input placeholder="Number" type="text" name="editstreetNumber" class="form-control" v-model="editCustomer.streetNumber" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-row">
                      <div class="col-md-4">
                        <label for="title">{{__('vue.zip')}}</label>
                        <input placeholder="ZIP" type="text" name="editzip" class="form-control" v-model="editCustomer.zip" @blur="zipChanged" />
                      </div>
                      <div class="col-md-8">
                        <label for="title">{{__('vue.city')}}</label>
                        <input placeholder="City" type="text" name="editcity" id ="editcity" class="form-control" v-model="editCustomer.city" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="title">{{__('vue.country')}}</label>
                    <input placeholder="Country" type="text" name="editcountry" class="form-control" v-model="editCustomer.country" />
                  </div>
                  <div class="form-group">
                    <div class="form-row">
                      <div class="col-md-8">
                        <label for="title">{{__('vue.vat_number')}}</label>
                        <input placeholder="VAT" type="text" name="editvat" class="form-control" v-model="editCustomer.vat" />
                      </div>
                      <div class="col-md-4">
                        <label for="title">{{__('vue.vat_type')}}</label>
                        <select name="editvatType" class="form-control" v-model="editCustomer.vatType">
                          <option value="0" selected="selected">0%</option>
                          <option value="12">12%</option>
                          <option value="6">6%</option>
                          <option value="21">21%</option>
                          <option value="intra">{{__('vue.intra')}}</option>
                          <option value="mc">{{__('vue.mc')}}</option>
                          <option value="no">{{__('vue.no_vat')}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="title">{{__('vue.payment_condition')}}'</label>
                        <input placeholder="Payment condition (days):" type="number" name="editpaymentCondition" class="form-control" v-model="editCustomer.paymentCondition" />
                      </div>
                    </div>
                  </div>
                  <a v-on:click="saveCustomerClicked" class="btn btn-primary">{{__('vue.save_customer')}}</a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </template>

<script>
export default  {
 data() {
   return {
     dummyToggle:0,
     customers: [],
     forceCustomerUpdate: 0,
     selectedCustomer: this.customer,
     editCustomer: this.customer,
     addCustomer: {},
     hasError: false,
     error: "",
     columns:['id','company','vat', 'city'],
     options:{
      headings: {
                id: _.get(window.i18n, 'vue.id'),
                company: _.get(window.i18n, 'vue.company'),
                vat: _.get(window.i18n, 'vue.vat_number'),
                city: _.get(window.i18n, 'vue.city')
            },
        texts: {
                count: "Showing {from} to {to} of {count} records|{count} records|One record",
                first: 'First',
                last: 'Last',
                filter: _.get(window.i18n, 'vue.filter'),
                filterPlaceholder: _.get(window.i18n, 'vue.filterPlaceholder'),
                limit: "Records:",
                page: "Page:",
                noResults: _.get(window.i18n, 'vue.noResults'),
                filterBy: "Filter by {column}",
                loading: 'Loading...',
                defaultOption: 'Select {column}',
                columns: 'Columns'
        },
     }
   }
 },
 props: [
   'customer',
   'qiccustomer'
 ],
 mounted () {
   this.loadCustomers();
   this.customers.forEach(function(entry) {
     entry.id = entry.code; // we get line-id, but need product id as the id.
   });
   if (localStorage.getItem('customer')) this.selectedCustomer = JSON.parse(localStorage.getItem('customer'));

 },
 created() {
  if (this.qiccustomer && this.qiccustomer.customer_id) {
    this.selectedCustomer = this.qiccustomer;
    this.selectedCustomer.id = this.qiccustomer.customer_id;
  }
  if (!this.customer) {
    this.editCustomer = {}
  }
 },
 computed: {
 },
 methods: {
  rowClicked(row) {
    for (let i = 0; i < this.customers.length; i++) {
      if (this.customers[i].id == row.row.id) {
        this.selectedCustomer = this.customers[i];
      }
    }
   this.editCustomer = this.selectedCustomer;
   this.forceCustomerUpdate = 1;
   localStorage.setItem('customer', JSON.stringify(this.selectedCustomer));
   $("#choose-customer").modal('hide');
  },
  zipChanged(e) {
    var d = e.target.value;
    axios.get('/api/zip-lookup?zip=' + d).then(res => {
      console.log(res.data.name);
      $('#city').val(res.data.name);
      this.addCustomer.city = res.data.name;
      this.editCustomer.city = res.data.name;
    });
  },
  loadCustomers() {
       this.$http.get('/api/customers', {headers: {'Authorization': 'Bearer ' + window.Laravel.apiToken}}).then(response => {
         this.customers = response.body;
         if (this.customers.length == 1) {
           console.log("Only 1 customer => setting!");
            this.selectedCustomer = this.customers[0];
          }
       }, response => {
       });
   },
   saveCustomerClicked() {
    $("#choose-customer").modal('hide');
    this.$http.post('/api/edit-customer', JSON.stringify(this.editCustomer), {headers: {'Authorization': 'Bearer ' + window.Laravel.apiToken}}).then(response => {
       if (response.data["success"] == "OK") {
         this.selectedCustomer = response.data["customer"];
         this.editCustomer = this.selectedCustomer;
         console.log(this.selectedCustomer);
         this.hasError = false;
         $("#choose-customer").modal('hide');
         this.forceCustomerUpdate = 1;
       }
       else {
         this.hasError = true;
         this.error ="Error";
       }
     }, response => {
     });
   },
   addCustomerClicked() {
     this.$http.post('/api/add-customer', JSON.stringify(this.addCustomer), {headers: {'Authorization': 'Bearer ' + window.Laravel.apiToken}}).then(response => {
       if (response.data["success"] == "OK") {
         this.selectedCustomer = response.data["customer"];
         this.editCustomer = this.selectedCustomer;
         console.log(this.selectedCustomer);
         this.hasError = false;
         $("#choose-customer").modal('hide');
       }
       else {
         this.hasError = true;
         this.error ="Error";
       }
     }, response => {
     });
   }
 }
}
</script>
