
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import $ from 'jquery';
window.$ = window.jQuery = $;
import 'jquery-ui/ui/widgets/autocomplete.js';
import 'jquery-ui/ui/widgets/sortable.js';

import draggable from 'vuedraggable'

import QuoteProductListComponent from './components/Quote-Product-List.vue';
import CustomerChooserComponent from './components/Customer-Chooser.vue';

Vue.component('quote-product-list', QuoteProductListComponent);
Vue.component('customer-chooser', CustomerChooserComponent);

import VueSessionStorage from "vue-sessionstorage";
Vue.use(VueSessionStorage);


const app = new Vue({
    el: '#app'
});

import Swal from 'sweetalert2';

window.deleteConfirm = function(formId, icon, text)
{
    Swal.fire({
        icon: icon,
        text: text,
        showCancelButton: true,
        confirmButtonText: Lang.get('lang.Delete'),
        cancelButtonText:  Lang.get('lang.Cancel'),
        confirmButtonColor: '#e3342f',
    }).then((result) => {
        if (result.isConfirmed) {
            document.getElementById(formId).submit();
        }
    });
}

const Parser = require('expr-eval').Parser;
$(".price-fix").change(function() {
  this.value.replace(',', '.');
  this.value = Parser.parse(this.value).evaluate().toFixed(2);
  this.dispatchEvent(new Event('input'));
})
