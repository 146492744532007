
<template>

  <div class="container">
    <div class="row">
        <label>Products</label>
    </div>
    <div class="row mb-3">
        <button type="button" class="btn btn-success mr-3" data-toggle="modal" data-target="#addedit-product">{{__('vue.add_product')}}</button>
        <button type="button" class="btn btn-success mr-3" data-toggle="modal" data-target="#addedit-title">{{__('vue.add_title')}}</button>
        <button type="button" class="btn btn-success" data-toggle="modal" data-target="#addedit-subtitle">{{__('vue.add_subtitle')}}</button>
    </div>
    <div class="row">
      <table class="table table-bordered" id="products-table">
        <input type="hidden" name="pcount" v-model="products.length">
        <thead>
          <tr>
            <th></th>
            <th>Title</th>
            <th width=10%>{{__('vue.quantity')}}</th>
            <th width=15%>{{__('vue.price_ex_vat')}}</th>
            <th id="vatTypeHeader" width=10%>{{__('vue.vat_type')}}</th>
            <th width=120px>{{__('vue.actions')}}</th>
          </tr>
        </thead>
        <draggable v-model="products" tag="tbody">
          <tr v-for="(product, index) in products" :key="product.index">
            <input type="hidden" v-bind:name="inputName(index, 'ptitle')" v-model="product.description">
            <input type="hidden" v-bind:name="inputName(index, 'pmode')" v-model="product.mode">
            <template v-if="product.mode == 'product'">
              <td><i class="fa fa-arrows-alt" aria-hidden="true"></i></td>
              <td><textarea class="form-control" rows=3 cols=60 v-bind:name="inputName(index, 'pdescription')" v-model="product.description"></textarea></td>
              <td class="text-right"><input class="form-control" size=10 type="text" @change="updateStorage" v-bind:name="inputName(index, 'pquantity')" v-model="product.quantity"></td>
              <td class="text-right">
                <input class="form-control" size=10 @change="updateStorage" v-bind:name="inputName(index, 'psell')" v-model="product.sellPriceJSON" v-on:blur="onPriceChange">
              </td>

              <td class="text-right">
                <select class="form-control" @change="updateStorage" v-bind:name="inputName(index, 'pvat')" v-model="product.vat" v-on:change="onVATChange">
                  <option value="0">0%</option>
                  <option value="6">6%</option>
                  <option value="12">12%</option>
                  <option value="21">21%</option>
                  <option value="intra">{{__('vue.intra')}}</option>
                  <option value="mc">{{__('vue.mc')}}</option>
                  <option value="no">{{__('vue.no_vat')}}</option>
                </select>
              </td>
              <td width=120px>
                  <button class="btn btn-primary" @click.prevent="addProductClicked(product)"><i class="fa fa-save"></i></button>
                  <button class="btn btn-danger" @click.prevent="deleteProduct(product)"><i class="fa fa-remove"></i></button>
              </td>
              <input type="hidden" v-bind:name="inputName(index, 'pid')" v-model="product.id">
            </template>
            <template v-else-if="product.mode == 'title1'">
              <td><i class="fa fa-arrows-alt" aria-hidden="true"></i></td>
              <td colspan="4"><textarea class="form-control" rows=4 cols=60 v-bind:name="inputName(index, 'pdescription')" v-model="product.description"></textarea></td>
              <td width=120px>
                  <button class="btn btn-primary" @click.prevent="editProduct(product)"><i class="fa fa-edit"></i></button>
                  <button class="btn btn-danger" @click.prevent="deleteProduct(product)"><i class="fa fa-remove"></i></button>
              </td>
            </template>
            <template v-else-if="product.mode == 'title2'">
              <td><i class="fa fa-arrows-alt" aria-hidden="true"></i></td>
              <td colspan="4"><textarea class="form-control" rows=4 cols=60 v-bind:name="inputName(index, 'pdescription')" v-model="product.description"></textarea></td>
              <td width=120px>
                  <button class="btn btn-primary" @click.prevent="editProduct(product)"><i class="fa fa-edit"></i></button>
                  <button class="btn btn-danger" @click.prevent="deleteProduct(product)"><i class="fa fa-remove"></i></button>
              </td>
            </template>
          </tr>
        </draggable>
        <tfoot>
          <tr class="not-sortable">
            <td></td>
            <td>{{products.length}} items</td>
            <td></td>
            <td class="text-right">{{ (this.getTotalPrice()).toFixed(2) }}€</td>
            <input type="hidden" name="totalExVAT" :value="this.getTotalPrice()">
            <td>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <!-- Create Product Modal - Used to save a product to the product database on server  -->
    <div class="modal fade" id="addproduct-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel">{{__('vue.add_product')}}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancelAddProduct"><span aria-hidden="true">×</span></button>
          </div>
          <div class="modal-body">
           <form method="POST" enctype="multipart/form-data" v-on:submit.prevent>
             <div class="form-group">
               <label for="title">{{__('vue.title')}}:</label>
               <input placeholder="Title" type="text" name="title" class="form-control" v-model="addProduct.title" />
             </div>
             <div class="form-group">
               <label for="title">{{__('vue.description')}}:</label>
               <textarea class="form-control" rows=3 cols=60 type="text" name="description" v-model="addProduct.description" />
             </div>
             <div class="form-group">
               <label for="title">{{__('vue.code')}}:</label>
               <input placeholder="Code" type="text" name="code" class="form-control" v-model="addProduct.code" />
             </div>
             <div class="form-group">
               <label for="title">{{__('vue.buy_price')}}:</label>
               <input placeholder="Buy price" type="text" name="buyPriceEx" class="form-control price-fix" v-model="addProduct.buyPriceEx" />
             </div>
             <div class="form-group">
               <label for="title">{{__('vue.sell_price')}}:</label>
               <input placeholder="Sell price" type="text" name="sellPriceEx" class="form-control price-fix" v-model="addProduct.sellPriceEx" />
             </div>
             <div class="form-group">
               <label for="title">{{__('vue.external_product_id')}}:</label>
               <input placeholder="External product id" type="text" name="externalProductId" class="form-control" v-model="addProduct.externalProductId" />
             </div>

             <a id="addproduct-savebtn" v-on:click="saveProductClicked" class="btn btn-primary">{{__('vue.save')}}</a>
           </form>
          </div>
        </div>
      </div>
    </div>
        <!-- CreateEdit Title Modal -->
    <div class="modal fade" id="addedit-title" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 v-if="addEditMode == 0" class="modal-title" id="myModalLabel">{{__('vue.add_title') }}</h4>
            <h4 v-if="addEditMode == 1" class="modal-title" id="myModalLabel">{{__('vue.edit_title') }}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancelAddEditTitle"><span aria-hidden="true">×</span></button>
          </div>
          <div class="modal-body">
           <form method="POST" enctype="multipart/form-data" v-on:submit.prevent>
             <div class="form-group">
               <label for="title">{{__('vue.title')}}:</label>
               <input placeholder="Title" type="text" name="title" class="form-control" v-model="addEditItem.description" />
             </div>
             <a v-if="addEditMode == 0" v-on:click="addTitle" class="btn btn-primary">{{__('vue.save')}}</a>
             <a v-if="addEditMode == 1" v-on:click="updateItem" class="btn btn-primary">{{__('vue.save')}}</a>
           </form>
          </div>
        </div>
      </div>
    </div>

    <!-- CreateEdit SubTitle Modal -->
    <div class="modal fade" id="addedit-subtitle" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 v-if="addEditMode == 0" class="modal-title" id="myModalLabel">{{__('vue.add_subtitle')}}</h4>
            <h4 v-if="addEditMode == 1" class="modal-title" id="myModalLabel">{{__('vue.edit_subtitle')}}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancelAddEditSubtitle"><span aria-hidden="true">×</span></button>
          </div>
          <div class="modal-body">
           <form method="POST" enctype="multipart/form-data" v-on:submit.prevent>
             <div class="form-group">
               <label for="subtitle">{{__('vue.subtitle')}}:</label>
               <input placeholder="Subtitle" type="text" name="subtitle" class="form-control" v-model="addEditItem.description" />
             </div>
             <a v-if="addEditMode == 0" v-on:click="addSubtitle" class="btn btn-primary">{{__('vue.save')}}</a>
             <a v-if="addEditMode == 1" v-on:click="updateItem" class="btn btn-primary">{{__('vue.save')}}</a>
           </form>
          </div>
        </div>
      </div>
    </div>
        <!-- Create Edit Item Modal -->
       <div class="modal fade" id="addedit-product" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
         <div class="modal-dialog modal-lg" role="document">
           <div class="modal-content">
             <div class="modal-header">
               <h4 v-if="addEditMode == 0" class="modal-title" id="myModalLabel">{{__('vue.add_product')}}</h4>
               <h4 v-if="addEditMode == 1" class="modal-title" id="myModalLabel">{{__('vue.edit_product')}}</h4>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancelAddEditProduct"><span aria-hidden="true">×</span></button>
             </div>
             <div class="modal-body">
               <nav v-if="addEditMode == 0">
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">{{__('vue.product')}}</a>
                  <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">{{__('vue.custom')}}</a>
                </div>
              </nav>
              <div class="tab-content" id="nav-tabContent">
                <div v-if="addEditMode == 0" class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <form method="POST" enctype="multipart/form-data" v-on:submit.prevent="createItem">
                    <v-client-table :data="allProducts" :columns="columns" :options="options" @row-click="rowClicked" ></v-client-table>
                  </form>
                </div>
                <div class="tab-pane fade" v-bind:class="{show:addEditMode, active:addEditMode}" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <form method="POST" enctype="multipart/form-data" v-on:submit.prevent>
                    <div class="form-group">
                      <label for="code">{{__('vue.description')}}:</label>
                      <textarea placeholder="Description" cols=40 rows=4 name="description" class="form-control" v-model="addEditItem.description" />
                    </div>
                    <div class="form-group">
                      <label for="quantity">{{__('vue.quantity')}}:</label>
                      <input placeholder="Quantity" type="number" name="code" class="form-control" v-model="addEditItem.quantity" />
                    </div>
                    <div class="form-group">
                      <label for="vat">{{__('vue.vat_type')}}:</label>
                      <select name="vat" class="form-control" v-model="addEditItem.vat">
                        <option value="0">0%</option>
                        <option value="6">6%</option>
                        <option value="12">12%</option>
                        <option value="21">21%</option>
                        <option value="intra">{{__('vue.intra')}}</option>
                        <option value="mc">{{__('vue.mc')}}</option>
                        <option value="no">{{__('vue.no_vat')}}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="sell">{{__('vue.price_ex_vat')}}:</label>
                      <input placeholder="Price ex VAT" type="text" name="sell" class="form-control" v-model="addEditItem.sellPriceJSON" v-on:blur="onPriceChange"/>
                    </div>
                    <a v-if="addEditMode == 0" v-on:click="addCustomItem" class="btn btn-primary">{{__('vue.add')}}</a>
                    <a v-if="addEditMode == 1" v-on:click="updateItem" class="btn btn-primary">{{__('vue.add')}}</a>
                  </form>
                </div>
              </div>
             </div>
             <div class="modal-footer">
                  <div id="addedit-product-succss" class="alert alert-success btn-block" role="alert">
                  </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </template>

<script>
import draggable from 'vuedraggable'

export default  {
  components: {
            draggable,
        },
  data() {
    return {
      dummyToggle:0,
      productIdToFind:0,
      products: this.iproducts,
      addProduct:0,
      productsOrder:[],
      allProducts: [],
      addEditItem: {},
      addEditMode: 0,
      columns:['id','code','description', 'sellPriceJSON'],
      options:{
        headings: {
                id: _.get(window.i18n, 'vue.id'),
                code: _.get(window.i18n, 'vue.code'),
                description: _.get(window.i18n, 'vue.description'),
                sellPriceJSON: _.get(window.i18n, 'vue.price_ex_vat')
            },
        texts: {
                count: "Showing {from} to {to} of {count} records|{count} records|One record",
                first: 'First',
                last: 'Last',
                filter: _.get(window.i18n, 'vue.filter'),
                filterPlaceholder: _.get(window.i18n, 'vue.filterPlaceholder'),
                limit: "Records:",
                page: "Page:",
                noResults: _.get(window.i18n, 'vue.noResults'),
                filterBy: "Filter by {column}",
                loading: 'Loading...',
                defaultOption: 'Select {column}',
                columns: 'Columns'
        },
      }
    }
  },
  props: [
    'iproducts',
    'customer'
  ],
  mounted () {
    this.loadItems();
    this.prepareNewItem();
    $("#addedit-product-succss").hide();
    if (localStorage.getItem('products')) this.products = JSON.parse(localStorage.getItem('products'));
    var i = 0;
    this.products.forEach(function(entry) {
      entry.id = entry.product_id;
      entry.index = i;
      i++;
      entry.code = entry.ref;
      if (!isNaN(entry.priceEx))
        entry.sellPriceJSON = entry.priceEx / 100.;
      if (!isNaN(entry.sellPriceEx))
        entry.sellPriceJSON = entry.sellPriceEx / 100.;
      entry.vat = entry.vat;
    });
    $('#addedit-product').on('hide.bs.modal', function (e) {
      this.addEditMode = 0
    })
    $('#addedit-title').on('hide.bs.modal', function (e) {
      this.addEditMode = 0
    })
    $('#addedit-subtitle').on('hide.bs.modal', function (e) {
      this.addEditMode = 0
    })
    this.verifyVAT();
  },
  computed: {
  },
  methods: {
    getDefaultVAT() {
      if (localStorage.getItem('customer')) {
        var c = JSON.parse(localStorage.getItem('customer'));
        console.log('Default vat: ' + c.vatType);
        return c.vatType;
      }
      else {
        if (this.customer) {
          console.log('Default vat: ' + this.customer.vatType);
          return this.customer.vatType;
        }
      }
      console.log('No default VAT found');

    },
    addCustomItem() {
      this.products.push(this.addEditItem)
      this.prepareNewItem();
      this.updateStorage();
    },
    inputName(index, dataname){
      return dataname+"-"+index
    },
    onVATChange(e) {
      this.verifyVAT();
    },  
    verifyVAT() {
      var str = "";
      $("#vatTypeHeader").removeClass('btn-danger');
      for (let i = 0; i < this.products.length; i++) {
        if (str.length) {
          if (this.products[i].vat !== str) {
            $("#vatTypeHeader").addClass('btn-danger');
          }
        }
        str = this.products[i].vat;
      }
    },
    rowClicked(row) {
    //  console.log(row.row)
      var p = $.extend(true, {}, row.row);
      p['quantity'] = 1;
      p['vat'] = this.getDefaultVAT();
      p['mode'] = 'product';
      this.products.push(p)
      this.updateStorage();
      $("#addedit-product-succss").html(p.title);
      $("#addedit-product-succss").fadeIn();
      $("#addedit-product-succss").fadeOut(1000);
      this.verifyVAT();
    },
    loadItems() {
        this.$http.get('/api/products', {headers: {'Authorization': 'Bearer ' + window.Laravel.apiToken}}).then(response => {
          this.allProducts = response.body;
          this.allProducts.forEach(function(entry) {
            if (!isNaN(entry.priceEx))
              entry.sellPriceJSON = entry.priceEx / 100.;
            if (!isNaN(entry.sellPriceEx))
              entry.sellPriceJSON = entry.sellPriceEx / 100.;
          });
        }, response => {
        });
    },
    prepareNewItem() {
      this.addEditItem = {"quantity": 1, "vat": this.getDefaultVAT(), "mode": "product", "sellPriceJSON": 0};
    },
    addTitle() {
      this.addEditItem["mode"] = "title1";
      this.products.push(this.addEditItem)
      this.updateStorage();
      this.prepareNewItem();
      $("#addedit-title").modal('hide');
    },
    addSubtitle() {
      this.addEditItem["mode"] = "title2";
      this.products.push(this.addEditItem)
      this.updateStorage();
      this.prepareNewItem();
      $("#addedit-subtitle").modal('hide');
    },
    createItem() {
      this.products.push(this.addEditItem)
      this.updateStorage();
      this.prepareNewItem();
      $("#addedit-product").modal('hide');
    },
    createCustomItem() {
      $("#addedit-product").modal('hide');
    },
    editProduct(product) {
      this.addEditItem = Object.assign({}, product);
      this.addEditItem.idx = this.products.indexOf(product);
      this.addEditMode = 1
      if (product.mode == 'product') {
        $("#addedit-product").modal('show');
      }
      else if (product.mode == 'title1') {
        $("#addedit-title").modal('show');
      }
      else if (product.mode == 'title2') {
        $("#addedit-subtitle").modal('show');
      }
    },
    onPriceChange(e) {
      // console.log(e);
      const Parser = require('expr-eval').Parser;
      e.target.value = Parser.parse(e.target.value.replace(',', '.')).evaluate().toFixed(2);
      e.target.dispatchEvent(new Event('input'))
    },
    updateStorage() {
      // console.log("Update storage");
      localStorage.setItem('products', JSON.stringify(this.products));
    },
    updateItem() {
      this.products.splice(this.addEditItem.idx, 1);
      this.products.splice(this.addEditItem.idx, 0, this.addEditItem);
      this.updateStorage();
      if (this.addEditItem.mode == 'product') {
        $("#addedit-product").modal('hide');
      }
      else if (this.addEditItem.mode == 'title1') {
        $("#addedit-title").modal('hide');
      }
      else if (this.addEditItem.mode == 'title2') {
        $("#addedit-subtitle").modal('hide');
      }
      verifyVAT();
      this.addEditMode = 0
    },
    cancelAddEditTitle() {
      this.addEditMode = 0
    },
    cancelAddEditSubtitle() {
      this.addEditMode = 0
    },
    cancelAddEditProduct() {
      this.addEditMode = 0
    },
    deleteProduct(product) {
      this.products.splice(this.products.indexOf(product), 1);
      this.updateStorage();
      this.verifyVAT();
    },
    cancelAddProduct() {
      $("#addproduct-modal").modal('hide');
    },
    addProductClicked(product) {
      $("#addproduct-savebtn").prop('value', 'Save')
      $("#addproduct-savebtn").prop('disabled', false);
      this.addProduct = product;
      this.addProduct.buyPriceEx = 0.0;
      this.addProduct.sellPriceEx = product.sellPriceJSON;
      this.addProduct.title = product.description;
      this.addProduct.code = "";
      console.log(this.addProduct);
      $("#addproduct-modal").modal('show');
    },
    saveProductClicked() {
      $("#addproduct-savebtn").prop('value', 'Sending..')
      $("#addproduct-savebtn").prop('disabled', true);
      this.$http.post('/api/add-product', JSON.stringify(this.addProduct), {headers: {'Authorization': 'Bearer ' + window.Laravel.apiToken}}).then(response => {
        if (response.data["success"] == "OK") {
          console.log("OK");
          // $("#myElem").show().delay(5000).fadeOut();
          $("#addproduct-modal").delay(1000).fadeOut();
          setTimeout(function() {
            $("#addproduct-modal").modal('hide');
          }, 1500);
        }
        else {
          console.log("NOK");
          $("#addproduct-savebtn").value = "Failed!";
          // $("#myElem").show().delay(5000).fadeOut();
          //$("#addproduct-modal").modal('hide');
        }
      }, response => {
      });
    },
    selectItem() {
    },
    getTotalPrice() {
      var total = 0;
      this.products.forEach(function(entry) {
        // total = total + parseFloat(entry.sellPriceJSON) * parseFloat(entry.quantity);
        var lineTotal = parseFloat(entry.sellPriceJSON) * parseFloat(entry.quantity);
        if (!isNaN(lineTotal)) {
          total += lineTotal;
        }
      });
      return total;
    },
  }
}
</script>
